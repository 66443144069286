<template>
  <div class="sku-container">
    <div class="sku-print" id="print-a" ref="print">
      <div class="tittle">
        <h3>{{data.merchantName}}</h3>
        <div class="barcode">
          <p>{{data.deliveryOrderNum}}</p>
          <canvas :class="'barcodeCanvas'+uuid"></canvas>
        </div>
      </div>
      <div class="info">
        <div>
          <p>发货单号：{{data.deliveryOrderNum}}</p>
          <p v-if="data.packageOrderNum">装箱单号：{{data.packageOrderNum}}</p>
          <p>SKU总数：{{data.skuTotal}}</p>
          <p>采购员：{{data.operateName}}</p>
        </div>
        <div>
          <p>生产订单号：{{data.purchaseOrderNum}}</p>
          <p>物流单号：{{data.logisticsNumber}}</p>
          <p>实际发货件数：{{data.deliveryGoodsNumTotal}}</p>
          <p>发货时间：{{data.createTime}}</p>
        </div>
      </div>
      <div class="print-table">
        <table style="width: 100%">
          <thead>
            <th>图片</th>
            <th>SPU</th>
            <th>SKU</th>
            <th style="width:150px">销售属性</th>
            <th style="width:200px">商品名称</th>
            <th style="width:50px">实际发货数量</th>
          </thead>
          <tbody>
            <tr v-for="(item,index) in data.items" :key="'row_'+index">
              <td>
                <SlImage size="8rem" :src="item.image" />
              </td>
              <td>{{item.spuCode}}</td>
              <td>{{item.skuCode}}</td>
              <td>{{item.skuAttribute}}</td>
              <td>{{item.goodsName}}</td>
              <td>{{item.deliveryGoodsNum}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import JsBarcode from 'jsbarcode'

export default {
  name: 'OemInvoicePrint',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    uuid: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {

    }
  },
  watch: {
    data () {
      this.$nextTick(() => {
        this.renderBarcode()
      })
    }
  },
  mounted () {
    this.renderBarcode()
  },
  methods: {
    renderBarcode () {
      JsBarcode('.barcodeCanvas' + this.uuid, this.data.deliveryOrderNum, {
        height: 30,
        displayValue: false
      })
    }
  }
}
</script>
<style scoped lang="scss">
.sku-print {
  width: 100%;
  .tittle {
    text-align: center;
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 28px;
      text-align: center;
      width: 50%;
    }
    .barcode {
      width: 50%;
      p {
        margin-bottom: 5px;
      }
    }
  }
  .info {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    line-height: 1.5;
  }

  .print-table {
    table {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid #ebeef5;
    }
    th,
    td {
      line-height: 1.5;
      text-align: center;
      border-top: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
    }

    th {
      padding: 1em;
    }

    td {
      padding: 0.5em;
    }
  }
}
</style>
