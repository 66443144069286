const chinesePointCode = {
  'a': [21834, 38463, 38165],
  'ai': [22467, 25384, 21710, 21769, 21696, 30353, 30284, 34108, 30702, 33406, 30861, 29233, 38552, 35830, 25457, 22003, 21964, 23250, 29815, 26279, 30777, 38207, 38701],
  'an': [38797, 27688, 23433, 20474, 25353, 26263, 23736, 33018, 26696, 35865, 22511, 25566, 29364, 24245, 26697, 38133, 40524, 39032, 40687],
  'ang': [32942, 26114, 30414],
  'ao': [20985, 25942, 29100, 32753, 34948, 20658, 22885, 25034, 28595, 22387, 25303, 22007, 22114, 23705, 24274, 36968, 23210, 39580, 32881, 34735, 37834, 40140, 37846],
  'ba': [33453, 25420, 25170, 21485, 21543, 31494, 20843, 30116, 24052, 25300, 36299, 38774, 25226, 32793, 22365, 38712, 32610, 29240, 33543, 33757, 33798, 25453, 23708, 28766, 26487, 38063, 31889, 40069, 39747],
  'bai': [30333, 26575, 30334, 25670, 20336, 36133, 25308, 31255, 34204, 25520, 38836],
  'ban': [26001, 29677, 25644, 25203, 33324, 39041, 26495, 29256, 25198, 25292, 20276, 29923, 21322, 21150, 32458, 38442, 22338, 35955, 38051, 30242, 30285, 33320],
  'bang': [37030, 24110, 26758, 27036, 33152, 32465, 26834, 30917, 34444, 38225, 20621, 35876, 33953, 34691],
  'bao': [33502, 32990, 21253, 35090, 38649, 20445, 22561, 39281, 23453, 25265, 25253, 26292, 35961, 40077, 29190, 21241, 33862, 23424, 23394, 29042, 40488, 35091, 36277, 40837],
  'bo': [21093, 34180, 29627, 33760, 25773, 25320, 38069, 27874, 21338, 21187, 25615, 38082, 31636, 20271, 24091, 33334, 33046, 33162, 28196, 27850, 39539, 20147, 34115, 21877, 39293, 27287, 25816, 31028, 38073, 40513, 31800, 36315],
  'bei': [26479, 30865, 24754, 21329, 21271, 36744, 32972, 36125, 38049, 20493, 29384, 22791, 24811, 28953, 34987, 23387, 38466, 37046, 22500, 34003, 21591, 24619, 24726, 30874, 40526, 35097, 37950],
  'ben': [22868, 33519, 26412, 31528, 30042, 22348, 38171],
  'beng': [23849, 32503, 29997, 27893, 36454, 36856, 21802, 22051, 29967],
  'bi': [36924, 40763, 27604, 37145, 31508, 24444, 30887, 34006, 34109, 27605, 27609, 27606, 24065, 24199, 30201, 38381, 25949, 24330, 24517, 36767, 22721, 33218, 36991, 38491, 21269, 20211, 20478, 33432, 33628, 33656, 21537, 21716, 29428, 24243, 24846, 28375, 28638, 24380, 22947, 23138, 23318, 29863, 36146, 30016, 38091, 31189, 35048, 31578, 31621, 31718, 33325, 35166, 36344, 39616],
  'bian': [38829, 36793, 32534, 36140, 25153, 20415, 21464, 21342, 36776, 36777, 36779, 36941, 21310, 24321, 33476, 24557, 27764, 32527, 29048, 30765, 30885, 31289, 31366, 34649, 31550, 40138],
  'biao': [26631, 24426, 33176, 34920, 23114, 39584, 39121, 39129, 39130, 28780, 38230, 38259, 30253, 35057, 40148],
  'bie': [40150, 24971, 21035, 30250, 36457, 40152],
  'bin': [24428, 25996, 28626, 28392, 23486, 25672, 20647, 27996, 32548, 29602, 27553, 33169, 38228, 39628, 39699],
  'bing': [20853, 20912, 26564, 19993, 31177, 39292, 28851, 30149, 24182, 31104, 37044, 25682, 32480, 26507, 27103, 29177],
  'bu': [25429, 21340, 21754, 34917, 22496, 19981, 24067, 27493, 31807, 37096, 24598, 25290, 21343, 36875, 29951, 26209, 38042, 37293],
  'ca': [25830, 22163, 31012],
  'cai': [29468, 35009, 26448, 25165, 36130, 30572, 36393, 37319, 24425, 33756, 34081],
  'can': [39184, 21442, 34453, 27531, 24813, 24808, 28799, 39574, 29864, 31922, 40682],
  'cang': [33485, 33329, 20179, 27815, 34255, 20263],
  'cao': [25805, 31961, 27133, 26361, 33609, 33401, 22024, 28437, 34732, 33370],
  'ce': [21397, 31574, 20391, 20876, 27979, 20994, 24123, 24699],
  'ceng': [23618, 36461, 22092],
  'cha': [25554, 21449, 33580, 33590, 26597, 30900, 25661, 23519, 23700, 24046, 35815, 29497, 39303, 27722, 23033, 26440, 26946, 27086, 27307, 38039, 38200, 38258, 34921],
  'chai': [25286, 26612, 35962, 20394, 33544, 30245, 34431, 40839],
  'chan': [25600, 25530, 34633, 39307, 35863, 32544, 38130, 20135, 38416, 39076, 20865, 35844, 35894, 33927, 24283, 24527, 28538, 28598, 23409, 32700, 23157, 23319, 39587, 35271, 31109, 38241, 35043, 34814, 36500],
  'chang': [26124, 29462, 22330, 23581, 24120, 38271, 20607, 32928, 21378, 25950, 30021, 21809, 20513, 20261, 39727, 33484, 33750, 24476, 24581, 24797, 38410, 23100, 23270, 26166, 27653, 40115],
  'chao': [36229, 25220, 38046, 26397, 22066, 28526, 24034, 21557, 28818, 24586, 32457, 26177, 32790],
  'che': [36710, 25199, 25764, 25507, 24443, 28552, 22396, 23662, 30743],
  'chen': [37108, 33251, 36784, 23576, 26216, 24561, 27785, 38472, 36225, 34924, 31216, 35852, 25275, 21972, 23480, 29723, 27015, 32924, 32962, 30876, 40832],
  'cheng': [25745, 22478, 27225, 25104, 21576, 20056, 31243, 24809, 28548, 35802, 25215, 36894, 39563, 31204, 22485, 23882, 24501, 27976, 26536, 26621, 27160, 26207, 22605, 30624, 38102, 35022, 34511, 37234],
  'chi': [21507, 30196, 25345, 21273, 27744, 36831, 24347, 39536, 32827, 40831, 20360, 23610, 36196, 32709, 26021, 28861, 20666, 22656, 33450, 33548, 25611, 21489, 21735, 21883, 21988, 24435, 39276, 27826, 23224, 25941, 32989, 30489, 30517, 40497, 30235, 35115, 34473, 34733, 31518, 31722, 35913, 36357, 36383, 39761],
  'chong': [20805, 20914, 34411, 23815, 23456, 33594, 24545, 24999, 38131, 33375],
  'chou': [25277, 37228, 30068, 36364, 31264, 24833, 31609, 20167, 32504, 30597, 19985, 20454, 22323, 24113, 24774, 28340, 22959, 30259, 38624, 40075],
  'chu': [33261, 21021, 20986, 27249, 21416, 36487, 38148, 38607, 28353, 38500, 26970, 30784, 20648, 30679, 25616, 35302, 22788, 20109, 21005, 25015, 32460, 26485, 26990, 27159, 34573, 36464, 40668],
  'chuan': [25571, 24029, 31359, 26941, 20256, 33337, 21912, 20018, 25534, 33307, 24820, 36932, 24027, 27674, 38031, 38249, 33313],
  'chuang': [30126, 31383, 24162, 24202, 38383, 21019, 24582],
  'chui': [21561, 28810, 25462, 38180, 22402, 38514, 26864, 27084],
  'chun': [26149, 26943, 37255, 21767, 28147, 32431, 34850, 20419, 33724, 27788, 32939, 26384, 40529, 34685],
  'chuo': [25139, 32496, 34079, 36790, 36749, 38238, 36372, 40842],
  'ci': [30133, 33576, 30913, 38604, 36766, 24904, 29943, 35789, 27492, 21050, 36176, 27425, 33632, 21618, 23919, 40538, 34693, 31949, 36241],
  'cong': [32874, 33905, 22257, 21254, 20174, 19995, 20588, 33473, 28121, 39586, 29742, 29825, 26526],
  'cu': [20945, 31895, 37259, 31751, 29469, 27522, 36441],
  'cuan': [36479, 31713, 31388, 27718, 25786, 26133, 29224],
  'cui': [25703, 23828, 20652, 33030, 30209, 31929, 28140, 32736, 33795, 24756, 29824, 27057, 38585],
  'cun': [26449, 23384, 23544, 30923, 24534, 30388],
  'cuo': [25774, 25619, 25514, 25387, 38169, 21405, 33054, 38153, 30700, 30180, 40574, 36425, 36508],
  'da': [25645, 36798, 31572, 30249, 25171, 22823, 32823, 21714, 21970, 24603, 22962, 30136, 35105, 31530, 38780, 38801],
  'dai': [21574, 27513, 20643, 25140, 24102, 27526, 20195, 36151, 34955, 24453, 36910, 24608, 22509, 29977, 21588, 23729, 36840, 36911, 39552, 32464, 29619, 40667],
  'dan': [32829, 25285, 20025, 21333, 37112, 25528, 32966, 26086, 27694, 20294, 24814, 28129, 35806, 24377, 34507, 20155, 20747, 21353, 33807, 21846, 28601, 27280, 27546, 36181, 30472, 30213, 32835, 31658],
  'dang': [24403, 25377, 20826, 33633, 26723, 35872, 20988, 33770, 23445, 30720, 38107, 35014],
  'dao': [20992, 25443, 36424, 20498, 23707, 31095, 23548, 21040, 31291, 24764, 36947, 30423, 21480, 21825, 24521, 27950, 27672, 28952, 24529, 32411],
  'de': [24503, 24471, 30340, 38173],
  'deng': [36460, 28783, 30331, 31561, 30634, 20979, 37011, 22100, 23965, 25125, 30964, 38251, 31782],
  'di': [22564, 20302, 28404, 36842, 25932, 31515, 29380, 28068, 32735, 23265, 25269, 24213, 22320, 33922, 31532, 24093, 24351, 36882, 32532, 27664, 31860, 35787, 35867, 37048, 22395, 33692, 33659, 22016, 23075, 26594, 26851, 35276, 30757, 30898, 30535, 38237, 32669, 39606],
  'dian': [39072, 25474, 28359, 30872, 28857, 20856, 38747, 22443, 30005, 20291, 30008, 24215, 24806, 22880, 28096, 27583, 20022, 38461, 22379, 22493, 24005, 29623, 30300, 30315, 31775, 36398],
  'diao': [30857, 21500, 38613, 20939, 20993, 25481, 21514, 38035, 35843, 36730, 38110, 34601, 31900, 35970],
  'die': [36300, 29241, 30879, 34678, 36845, 35853, 21472, 20314, 22436, 22558, 25586, 21899, 28203, 36726, 29266, 29918, 35126, 32779, 36416, 40125, 40142],
  'ding': [19969, 30447, 21486, 38025, 39030, 40718, 38189, 23450, 35746, 20002, 20163, 21878, 29582, 33114, 30855, 30010, 38116, 30100, 32821, 37194],
  'dong': [19996, 20908, 33891, 25026, 21160, 26635, 20375, 24683, 20923, 27934, 22412, 21658, 23741, 23762, 22786, 27681, 33000, 33012, 30800, 40491],
  'dou': [20828, 25238, 26007, 38497, 35910, 36887, 30168, 34104, 38061, 31398, 31404, 34474, 31740, 37217],
  'du': [37117, 30563, 27602, 29322, 29420, 35835, 22581, 30585, 36172, 26460, 38208, 32922, 24230, 28193, 22930, 33423, 22047, 28174, 26911, 27216, 29261, 34873, 31491, 39633, 40681],
  'duan': [31471, 30701, 38203, 27573, 26029, 32526, 24406, 26932, 28997, 31766],
  'dui': [22534, 20817, 38431, 23545, 24636, 24989, 30867],
  'dun': [22697, 21544, 36466, 25958, 39039, 22244, 38045, 30462, 36929, 28822, 30744, 30981, 30457, 38246, 36280],
  'duo': [25479, 21702, 22810, 22842, 22427, 36530, 26421, 36346, 33333, 21057, 24816, 22549, 21636, 21722, 32525, 26561, 38094, 35056, 36401],
  'e': [34558, 23784, 40517, 20420, 39069, 35769, 23077, 24694, 21380, 25212, 36943, 37122, 39295, 22121, 35860, 22441, 22445, 33482, 33706, 33852, 21571, 24853, 23641, 23104, 36717, 26359, 33133, 30826, 38151, 38199, 40535, 39066, 40132],
  'en': [24681, 33981, 25665, 21780, 21999],
  'er': [32780, 20799, 32819, 23572, 39285, 27953, 20108, 36144, 36841, 29669, 38098, 40504, 40085],
  'fa': [21457, 32602, 31567, 20240, 20047, 38400, 27861, 29648, 22433, 30749],
  'fan': [34281, 24070, 30058, 32763, 27146, 30718, 38034, 32321, 20961, 28902, 21453, 36820, 33539, 36137, 29359, 39277, 27867, 34345, 24161, 29357, 26805, 25909, 29140, 30024, 36463],
  'fang': [22346, 33459, 26041, 32938, 25151, 38450, 22952, 20223, 35775, 32442, 25918, 21274, 37025, 24439, 38059, 33323, 40066],
  'fei': [33778, 38750, 21857, 39134, 32933, 21290, 35837, 21536, 32954, 24223, 27832, 36153, 33470, 29394, 24753, 28125, 22915, 32459, 32495, 27047, 33107, 26000, 25161, 31059, 30761, 38212, 30193, 34586, 31706, 32737, 38671, 40113],
  'fen': [33452, 37210, 21545, 27675, 20998, 32439, 22367, 28954, 27774, 31881, 22859, 20221, 24575, 24868, 31914, 20606, 28725, 26876, 24845, 40124, 40738],
  'feng': [20016, 23553, 26539, 34562, 23792, 38155, 39118, 30127, 28925, 36898, 20911, 32541, 35773, 22857, 20964, 20472, 37190, 33873, 27811, 30748],
  'fu': [20315, 21542, 22827, 25975, 32932, 23413, 25206, 25282, 36752, 24133, 27679, 31526, 20239, 20440, 26381, 28014, 28074, 31119, 34993, 24343, 29995, 25242, 36741, 20463, 37340, 26023, 33071, 33105, 24220, 33104, 36212, 21103, 35206, 36171, 22797, 20613, 20184, 38428, 29238, 33145, 36127, 23500, 35747, 38468, 22919, 32538, 21648, 21264, 20971, 37083, 33433, 33531, 33583, 33705, 33748, 21579, 24158, 28367, 33396, 23386, 39544, 32450, 26740, 36185, 40699, 40700, 32600, 31235, 39333, 34381, 34472, 34569, 34656, 34670, 40632, 36282, 36311, 40134],
  'ga': [22134, 22030, 34532, 23596, 21623, 23573, 23580, 26094, 38022],
  'gai': [35813, 25913, 27010, 38041, 30422, 28297, 19984, 38484, 22419, 25124, 36165, 33010],
  'gan': [24178, 29976, 26438, 26577, 31487, 32925, 36214, 24863, 31174, 25954, 36195, 22377, 33527, 23604, 25792, 27860, 28134, 28553, 32448, 27204, 26096, 30712, 30131, 37200],
  'gang': [20872, 21018, 38050, 32568, 32923, 32434, 23703, 28207, 25094, 32609, 39043, 31611],
  'gong': [26464, 24037, 25915, 21151, 24685, 40858, 20379, 36524, 20844, 23467, 24339, 24041, 27742, 25329, 36129, 20849, 34171, 24318, 21667, 29657, 32945, 34467, 34537, 35301],
  'gao': [31705, 30347, 39640, 33167, 32660, 31957, 25630, 38224, 31295, 21578, 30590, 35824, 37084, 33983, 34241, 32543, 27092, 27073, 26482, 38150],
  'ge': [21733, 27468, 25601, 25096, 40509, 33011, 30105, 21106, 38761, 33883, 26684, 38401, 38548, 38124, 20010, 21508, 39730, 20193, 21759, 22629, 21981, 32421, 25663, 33160, 30796, 38122, 38217, 35004, 39052, 34428, 33336, 39612, 39618],
  'gei': [32473],
  'gen': [26681, 36319, 20120, 33563, 21711, 33390],
  'geng': [32789, 26356, 24218, 32697, 22466, 32831, 26775, 21757, 36179, 40096],
  'gou': [38057, 21246, 27807, 33503, 29399, 22434, 26500, 36141, 22815, 20317, 35807, 23715, 36952, 23230, 32529, 35279, 24384, 40498, 31537, 31709, 38834],
  'gu': [36764, 33735, 21653, 31629, 20272, 27837, 23396, 22993, 40723, 21476, 34506, 39592, 35895, 32929, 25925, 39038, 22266, 38599, 22031, 35778, 33776, 21708, 23854, 27753, 26767, 36721, 29295, 29311, 32973, 33228, 27586, 30653, 32607, 38068, 38178, 29920, 40490, 40516, 30204, 34500, 37220, 35290, 40116, 39600, 40536],
  'gua': [21038, 29916, 21072, 23521, 25346, 35074, 21350, 35798, 21617, 26653, 40505],
  'guai': [20054, 25296, 24618, 21721],
  'guan': [26874, 20851, 23448, 20896, 35266, 31649, 39302, 32592, 24815, 28748, 36143, 20492, 33694, 25532, 28075, 30437, 40563, 40143],
  'guang': [20809, 24191, 36891, 29367, 26692, 33009, 30098],
  'gui': [29808, 35268, 22317, 30789, 24402, 40863, 38394, 36712, 39740, 35809, 30328, 26690, 26588, 36330, 36149, 21053, 21286, 21055, 24203, 23428, 22955, 26727, 28805, 26231, 30344, 31755, 40081, 40156],
  'gun': [36746, 28378, 26829, 20008, 34926, 32498, 30937, 40103],
  'guo': [38149, 37101, 22269, 26524, 35065, 36807, 39320, 34819, 22490, 25524, 21593, 22231, 24124, 23838, 29459, 26881, 34402, 38174, 32850, 34606, 34622, 34632],
  'ha': [21704],
  'hai': [39608, 23401, 28023, 27686, 20133, 23475, 39559, 21684, 21992, 39055, 37282],
  'han': [37219, 25000, 37039, 38889, 21547, 28085, 23506, 20989, 21898, 32597, 32752, 25788, 25421, 26097, 25022, 24717, 28938, 27735, 27721, 37015, 33761, 25750, 38426, 28698, 26199, 28947, 39060, 34486, 40766],
  'hen': [22831, 30165, 24456, 29408, 24680],
  'hang': [26477, 33322, 27782, 32471, 29673, 26689],
  'hao': [22741, 22158, 35946, 27627, 37085, 22909, 32791, 21495, 28009, 34181, 21989, 22150, 28640, 28751, 26122, 30355, 39074, 34461],
  'he': [21621, 21917, 33655, 33743, 26680, 31166, 21644, 20309, 21512, 30418, 35977, 38402, 27827, 28088, 36203, 35088, 40548, 36154, 35779, 21182, 22737, 34303, 21969, 21996, 38422, 30413, 34485, 32750],
  'hei': [22079, 40657],
  'heng': [21756, 20136, 27178, 34913, 24658, 35335, 34309],
  'hong': [36720, 21700, 28888, 34425, 40511, 27946, 23439, 24344, 32418, 40649, 35751, 33645, 34216, 38387, 27859],
  'hou': [21897, 20399, 29492, 21564, 21402, 20505, 21518, 22560, 24460, 36869, 30218, 31692, 31943, 40078, 39610],
  'hu': [21628, 20046, 24573, 29786, 22774, 33899, 32993, 34676, 29392, 31946, 28246, 24359, 34382, 21804, 25252, 20114, 27818, 25143, 20913, 21823, 22251, 23733, 29474, 24601, 24794, 27986, 28409, 29733, 27122, 36727, 35315, 28864, 29043, 25149, 25160, 31068, 40533, 40561, 31503, 37264, 26011],
  'hua': [33457, 21719, 21326, 29502, 28369, 30011, 21010, 21270, 35805, 21136, 27981, 39557, 26726, 38119, 31262],
  'huai': [27088, 24458, 24576, 28142, 22351, 36824, 36381],
  'huan': [27426, 29615, 26707, 32531, 25442, 24739, 21796, 30186, 35938, 28949, 28067, 23462, 24187, 37063, 22850, 22456, 25808, 22300, 27961, 28003, 28470, 23536, 36909, 32563, 38206, 40105, 39711],
  'huang': [33618, 24908, 40644, 30970, 34647, 31783, 30343, 20976, 24822, 29004, 26179, 24140, 24653, 35854, 38541, 24488, 28255, 28514, 36945, 29852, 32915, 30272, 34789, 31681, 40135],
  'hui': [28784, 25381, 36745, 24509, 24674, 34516, 22238, 27585, 24724, 24935, 21321, 24800, 26214, 36159, 31229, 20250, 28905, 27719, 35763, 35826, 32472, 35801, 33588, 33631, 34137, 21717, 21913, 38579, 27908, 24407, 32523, 29682, 26198, 24666, 34426, 34794, 40638],
  'hun': [33636, 26127, 23130, 39746, 27985, 28151, 35816, 39300, 38413, 28343, 32535],
  'huo': [35905, 27963, 20249, 28779, 33719, 25110, 24785, 38669, 36135, 31096, 25865, 22191, 22821, 38060, 38186, 38252, 32800, 34838],
  'ji': [20987, 22334, 22522, 26426, 30072, 31293, 31215, 31637, 32908, 39269, 36857, 28608, 35749, 40481, 23020, 32489, 32521, 21513, 26497, 26840, 36753, 31821, 38598, 21450, 24613, 30142, 27762, 21363, 23241, 32423, 25380, 20960, 33034, 24049, 34015, 25216, 20864, 23395, 20238, 31085, 21058, 24760, 27982, 23492, 23490, 35745, 35760, 26082, 24524, 38469, 22931, 32487, 32426, 23621, 19980, 20073, 21086, 20342, 20340, 33044, 22716, 33448, 33456, 33793, 33978, 34170, 25486, 21501, 21677, 21724, 21799, 23692, 23924, 27918, 24400, 23632, 39589, 30079, 29585, 26987, 27547, 25119, 25122, 36173, 35274, 29316, 40785, 30710, 32641, 23879, 31287, 30240, 30261, 34414, 31496, 31492, 26280, 36347, 36349, 38657, 40090, 40107, 39675, 40578],
  'jia': [22025, 26551, 22841, 20339, 23478, 21152, 33626, 39050, 36158, 30002, 38078, 20551, 31292, 20215, 26550, 39550, 23233, 20285, 37071, 25326, 23724, 27971, 36838, 29640, 25115, 32987, 24669, 38103, 38227, 30146, 34545, 31539, 34952, 36303],
  'jian': [27516, 30417, 22362, 23574, 31546, 38388, 29006, 20860, 32937, 33392, 22904, 32516, 33575, 26816, 26604, 30897, 30839, 25315, 25441, 31616, 20461, 21098, 20943, 33616, 27099, 37492, 36341, 36145, 35265, 38190, 31661, 20214, 20581, 33328, 21073, 39279, 28176, 28293, 28071, 24314, 20717, 35855, 35883, 33733, 33977, 25627, 22237, 28244, 36423, 35591, 32547, 26535, 26585, 26967, 25099, 25132, 29294, 29325, 27645, 33137, 30545, 38159, 40547, 35045, 31509, 31668, 32742, 36284, 36410, 40099, 38831],
  'jiang': [20725, 23004, 23558, 27974, 27743, 30086, 33931, 26728, 22870, 35762, 21280, 37233, 38477, 33587, 27930, 32475, 32560, 29343, 30995, 32809, 31976, 35911],
  'jiao': [34121, 26898, 30977, 28966, 33014, 20132, 37066, 27975, 39556, 23047, 22204, 25605, 38128, 30699, 20389, 33050, 29409, 35282, 39290, 32564, 32478, 21119, 25945, 37237, 36735, 36739, 21483, 20348, 20716, 33581, 25378, 22093, 23780, 24508, 23011, 32415, 25963, 30350, 40554, 34527, 37294, 36324, 40091],
  'jie': [31382, 25581, 25509, 30342, 31224, 34903, 38454, 25130, 21163, 33410, 26708, 26480, 25463, 30571, 31469, 27905, 32467, 35299, 22992, 25106, 34249, 33445, 30028, 20511, 20171, 30117, 35819, 23626, 20552, 35750, 35800, 21896, 21983, 29548, 23125, 23377, 26688, 29522, 30883, 38196, 30102, 34999, 39049, 34471, 32687, 40082, 39601, 39659],
  'jin': [24062, 31563, 26020, 37329, 20170, 27941, 35167, 32039, 38182, 20165, 35880, 36827, 38771, 26187, 31105, 36817, 28908, 28024, 23613, 21370, 33641, 22535, 22116, 39313, 24273, 22935, 32537, 29822, 27135, 36166, 35280, 38021, 38163, 34943, 30684],
  'jing': [21170, 33606, 20834, 33550, 30555, 26230, 40120, 20140, 24778, 31934, 31923, 32463, 20117, 35686, 26223, 39048, 38745, 22659, 25964, 38236, 24452, 30153, 38742, 31455, 31454, 20928, 21037, 20742, 38449, 33729, 29517, 25004, 27902, 36851, 24362, 23143, 32956, 33003, 33096, 26060],
  'jiong': [28847, 31384, 20866, 36837, 25155],
  'jiu': [25578, 31350, 32416, 29590, 38893, 20037, 28792, 20061, 37202, 21417, 25937, 26087, 33276, 33285, 21646, 23601, 30106, 20710, 21886, 38404, 26601, 26709, 40555, 36211, 39695],
  'ju': [38816, 25304, 29401, 30141, 39545, 33738, 23616, 21632, 30697, 20030, 27822, 32858, 25298, 25454, 24040, 20855, 36317, 36382, 38191, 20465, 21477, 24807, 28844, 21095, 20520, 35765, 33507, 33524, 33682, 25516, 36989, 23654, 29722, 26552, 26896, 27032, 27017, 27224, 29323, 39123, 38044, 38164, 31405, 35070, 36228, 37301, 36413, 40835, 38606, 38827],
  'juan': [25424, 40515, 23071, 20518, 30519, 21367, 32482, 37124, 29431, 28051, 26698, 34866, 38185, 38220, 38589],
  'jue': [25733, 25899, 25225, 25496, 20500, 29237, 35273, 20915, 35776, 32477, 21413, 21122, 35890, 30669, 34152, 22104, 23835, 29527, 23379, 29647, 26743, 27227, 29213, 38242, 36470, 35286],
  'jun': [22343, 33740, 38055, 20891, 21531, 23803, 20426, 31459, 27994, 37089, 39567, 25411, 29435, 30386, 31584, 40583],
  'ka': [21888, 21654, 21345, 20327, 21652, 33001],
  'ke': [21679, 22391, 33499, 26607, 26869, 30933, 39063, 31185, 22771, 21683, 21487, 28212, 20811, 21051, 23458, 35838, 23714, 24682, 28312, 39570, 32514, 29634, 36722, 27690, 30604, 38070, 30132, 31392, 34636, 39617],
  'kai': [24320, 25577, 26999, 20975, 24936, 21056, 22450, 33928, 24574, 24698, 38112, 38158],
  'kan': [21002, 22570, 21208, 22350, 30733, 30475, 20355, 20981, 33712, 33718, 25121, 40859, 30640],
  'kang': [24247, 24951, 31968, 25179, 25239, 20130, 28821, 22353, 20233, 38390, 38058],
  'kao': [32771, 25335, 28900, 38752, 23611, 26674, 29330, 38096],
  'ken': [32943, 21827, 22438, 24691, 22432, 35017, 39040],
  'keng': [21549, 24528, 38143],
  'kong': [31354, 24656, 23380, 25511, 20517, 23814, 31644],
  'kou': [25248, 21475, 25187, 23495, 33444, 34107, 21481, 30477, 31576],
  'ku': [26543, 21741, 31391, 33510, 37239, 24211, 35044, 21043, 22528, 21950, 32468, 39607],
  'kua': [22840, 22446, 25358, 36328, 33007, 20361],
  'kuai': [22359, 31607, 20393, 24555, 33967, 37072, 33929, 29423, 33037],
  'kuan': [23485, 27454, 39627],
  'kuang': [21281, 31568, 29378, 26694, 30719, 30518, 26103, 20917, 35795, 35827, 37021, 22329, 22844, 21712, 32425, 36150],
  'kui': [20111, 30420, 23743, 31397, 33909, 22862, 39745, 20608, 39304, 24871, 28291, 39319, 21294, 22804, 38551, 25542, 21945, 21919, 24733, 24870, 38421, 36917, 26252, 30589, 32873, 34672, 31697, 33278, 36332],
  'kun': [22372, 26118, 25414, 22256, 24707, 38403, 29736, 38175, 37260, 40114, 39649],
  'kuo': [25324, 25193, 24275, 38420, 34526],
  'la': [22403, 25289, 21895, 34593, 33098, 36771, 21862, 21068, 25722, 37003, 26095, 30764, 30220],
  'lai': [33713, 26469, 36182, 23811, 24469, 28062, 28625, 36169, 30544, 38140, 30302, 31809],
  'lan': [34013, 23146, 26639, 25318, 31726, 38417, 20848, 28572, 35888, 25597, 35272, 25042, 32518, 28866, 28389, 21833, 23706, 25044, 28452, 27012, 26003, 32625, 38247, 35124],
  'lang': [29701, 27028, 29436, 24266, 37070, 26391, 28010, 33704, 33943, 21879, 38406, 38162, 31234, 34690],
  'lao': [25438, 21171, 29282, 32769, 20332, 23013, 37226, 28889, 28061, 21792, 23810, 26675, 38097, 38137, 30184, 37290],
  'le': [21202, 20048, 32907, 20162, 21499, 22046, 27856, 40147],
  'lei': [38647, 38253, 34174, 30922, 32047, 20769, 22418, 25794, 31867, 27882, 32696, 35796, 33661, 21671, 28463, 23256, 32551, 27281, 32786, 37241],
  'ling': [26865, 20919, 25294, 29618, 33777, 38646, 40836, 38083, 20278, 32666, 20940, 28789, 38517, 23725, 39046, 21478, 20196, 37187, 22596, 33491, 21604, 22265, 27872, 32491, 26563, 26818, 29940, 32838, 34505, 32718, 40110],
  'leng': [26974, 24867],
  'li': [21400, 26792, 29313, 40654, 31729, 29432, 31163, 28435, 29702, 26446, 37324, 40100, 31036, 33673, 33620, 21519, 26647, 20029, 21385, 21169, 30782, 21382, 21033, 20616, 20363, 20432, 30178, 31435, 31890, 27813, 38582, 21147, 29827, 21737, 20458, 20442, 37094, 22364, 33480, 33669, 34016, 34268, 25449, 21590, 21811, 21937, 29441, 28327, 28583, 36902, 23052, 23264, 39562, 32545, 29662, 26533, 26638, 36729, 25150, 30778, 35400, 32633, 38146, 40514, 30112, 30124, 34510, 34570, 34849, 31520, 31717, 31901, 37300, 36318, 38643, 40097, 40162, 40679],
  'lian': [20457, 32852, 33714, 36830, 38256, 24265, 24604, 28063, 24088, 25947, 33080, 38142, 24651, 28860, 32451, 25371, 34105, 22849, 28491, 28610, 23048, 29711, 26973, 27539, 33217, 33190, 35042, 34826, 40098],
  'liang': [31918, 20937, 26753, 31921, 33391, 20004, 36742, 37327, 26238, 20142, 35845, 22682, 26891, 36361, 38739, 39753],
  'liao': [25769, 32842, 20698, 30103, 29134, 23525, 36797, 28518, 20102, 25730, 38243, 24278, 26009, 34044, 23589, 22073, 29536, 23534, 32557, 38028, 40553, 32802],
  'lie': [21015, 35010, 28872, 21155, 29454, 20925, 22482, 27916, 36244, 36496, 39715],
  'lin': [29747, 26519, 30967, 38678, 20020, 37051, 40158, 28107, 20955, 36161, 21533, 34106, 23961, 24298, 36980, 27305, 36762, 30645, 31932, 36495, 40607],
  'liu': [28316, 29705, 27060, 30827, 39311, 30041, 21016, 30244, 27969, 26611, 20845, 25249, 20603, 33932, 27862, 27983, 36955, 39581, 32506, 26066, 29080, 38157, 38223, 40552, 37775],
  'long': [40857, 32843, 21657, 31548, 31423, 38534, 22404, 25314, 38471, 24324, 22405, 33551, 27895, 29649, 26634, 32999, 30779, 30275],
  'lou': [27004, 23044, 25602, 31699, 28431, 38475, 21949, 23901, 38210, 30232, 32807, 34684, 39621],
  'lu': [33446, 21346, 39045, 24208, 28809, 25523, 21348, 34383, 40065, 40595, 30860, 38706, 36335, 36162, 40575, 28510, 31108, 24405, 38470, 25134, 22406, 25669, 25784, 22108, 27896, 28172, 28425, 29840, 26636, 27257, 36723, 36738, 36760, 27655, 33002, 38245, 40492, 40557, 31759, 33339, 40072],
  'lv': [39540, 21525, 38109, 20387, 26053, 23653, 23649, 32533, 34385, 27695, 24459, 29575, 28388, 32511, 25419, 38398, 27016, 33154, 31238, 35099],
  'luan': [23782, 23402, 28390, 21365, 20081, 26686, 40510, 37550],
  'lue': [25504, 30053, 38154],
  'lun': [36718, 20262, 20177, 27814, 32438, 35770, 22261],
  'luo': [33821, 34746, 32599, 36923, 38179, 31657, 39585, 35064, 33853, 27931, 39558, 32476, 20526, 33638, 25694, 29473, 27898, 26916, 33078, 38233, 30256, 38610],
  'ma': [22920, 40635, 29595, 30721, 34434, 39532, 39554, 22043, 21527, 21787, 29368, 23351, 26473, 40637],
  'mai': [22475, 20080, 40614, 21334, 36808, 33033, 21154, 33644, 21674, 38718],
  'man': [30610, 39314, 34542, 28385, 34067, 26364, 24930, 28459, 35881, 22657, 24148, 32550, 29107, 38232, 39071, 34728, 40151, 38804],
  'mang': [33426, 33579, 30450, 24537, 33725, 37017, 28461, 26406, 30829, 34770],
  'meng': [27667, 33804, 33945, 27308, 30431, 38192, 29467, 26790, 23391, 21200, 29965, 30626, 25077, 31006, 34427, 34594, 34835, 33355, 33384, 40702],
  'miao': [29483, 33495, 25551, 30596, 34256, 31186, 28218, 24217, 22937, 21941, 37000, 32520, 32554, 26474, 28156, 30471, 40523, 34609],
  'mao': [33541, 38170, 27611, 30683, 38086, 21359, 33538, 20882, 24125, 35980, 36152, 20372, 34980, 21206, 33542, 23745, 29761, 26164, 29286, 32772, 26052, 25035, 30592, 34513, 34661, 34762, 39654],
  'me': [20040],
  'mei': [29611, 26522, 26757, 37238, 38665, 29028, 27809, 30473, 23186, 38209, 27599, 32654, 26151, 23504, 22969, 23194, 22390, 33683, 23883, 29496, 28028, 28228, 26979, 38213, 40539, 34946, 39749],
  'men': [38376, 38391, 20204, 25194, 29599, 28950, 25041, 38036],
  'mi': [30511, 37274, 38753, 31964, 36855, 35868, 24357, 31859, 31192, 35269, 27852, 34588, 23494, 24130, 33416, 20886, 35879, 34364, 22055, 29461, 29551, 27752, 23443, 24365, 33042, 25929, 31992, 32315, 40587],
  'mian': [26825, 30496, 32501, 20885, 20813, 21193, 23081, 32517, 38754, 27796, 28238, 33148, 30468],
  'mie': [34065, 28781, 21673, 34843, 31742],
  'min': [27665, 25279, 30399, 25935, 24751, 38397, 33504, 23735, 38389, 27887, 29641],
  'ming': [26126, 34719, 40483, 38125, 21517, 21629, 20901, 33559, 28319, 26269, 30609, 37225],
  'miu': [35884],
  'mo': [25720, 25721, 34321, 27169, 33180, 30952, 25705, 39764, 25273, 26411, 33707, 22696, 40664, 27819, 28448, 23518, 38476, 35871, 33545, 34022, 39309, 23275, 38214, 31203, 30268, 32817, 34758, 35978, 35992],
  'mou': [35851, 29279, 26576, 21430, 21726, 23162, 30520, 37738],
  'mu': [25287, 29281, 20137, 22982, 27597, 22675, 26286, 24149, 21215, 24917, 26408, 30446, 30566, 29287, 31302, 20203, 33500, 21586, 27792, 27626, 38076],
  'na': [25343, 21738, 21584, 38048, 37027, 23068, 32435, 20869, 25466, 32941, 38222, 34930, 31660],
  'nai': [27670, 20035, 22902, 32784, 22856, 40720, 33407, 33816, 26608],
  'nan': [21335, 30007, 38590, 22218, 21891, 22241, 26976, 33129, 34683, 36199],
  'nao': [25376, 33041, 24700, 38393, 23404, 22452, 29489, 29785, 30791, 38105, 34546],
  'ne': [28118, 21602, 35767],
  'nei': [39297],
  'nen': [23273, 33021, 26520, 24641],
  'ni': [22958, 38675, 20522, 27877, 23612, 25311, 20320, 21311, 33147, 36870, 28346, 20274, 22381, 29450, 24617, 28384, 26165, 26062, 31074, 24925, 30568, 38092, 40117],
  'nian': [34091, 25288, 24180, 30910, 25781, 25467, 24565, 24319, 36743, 40655, 40071, 40118],
  'niang': [23064, 37247],
  'niao': [40479, 23615, 33553, 23346, 33074, 34949],
  'nie': [25423, 32834, 23421, 21870, 38218, 38221, 28037, 20060, 38503, 34326, 21995, 32896, 39070, 33260, 36433],
  'nin': [24744, 26592],
  'ning': [29406, 20957, 23425, 25319, 27870, 20318, 34021, 21659, 29999, 32845],
  'niu': [29275, 25197, 38062, 32445, 29379, 24568, 22942, 34484],
  'nong': [33043, 27987, 20892, 20396],
  'nu': [22900, 21162, 24594, 21622, 24081, 24361, 33004, 23397, 39549],
  'nv': [22899, 24679, 38037, 34884],
  'nuan': [26262],
  'nuenue': [34384],
  'nue': [30111, 35857],
  'nuo': [25386, 25062, 31983, 35834, 20649, 25638, 21903, 38168],
  'ou': [21734, 27431, 40485, 27572, 34261, 21589, 20598, 27812, 24580, 29935, 32806],
  'pa': [21866, 36276, 29228, 24085, 24597, 29750, 33897, 31586],
  'pai': [25293, 25490, 29260, 24472, 28227, 27966, 20467, 33934],
  'pan': [25856, 28504, 30424, 30928, 30460, 30036, 21028, 21467, 29247, 27886, 34978, 35195, 34784, 36434],
  'pang': [20051, 24222, 26049, 32810, 32982, 28354, 36868],
  'pao': [25243, 21638, 21032, 28846, 34957, 36305, 27873, 21263, 29389, 24214, 33068, 30129],
  'pei': [21624, 32986, 22521, 35060, 36180, 38506, 37197, 20329, 27803, 25482, 36756, 24084, 28128, 26054, 38187, 37253, 38664],
  'pen': [21943, 30406, 28243],
  'peng': [30768, 25256, 28921, 28558, 24429, 34028, 26842, 30844, 31735, 33192, 26379, 40527, 25447, 30896, 22383, 22539, 22061, 24614, 34779],
  'pi': [30738, 38713, 25209, 25259, 21128, 29749, 27607, 21860, 33086, 30130, 30382, 21305, 30174, 20731, 23617, 35692, 19989, 38516, 37043, 37099, 22318, 40729, 25815, 22140, 24192, 23218, 32432, 26503, 29971, 30565, 32628, 38093, 30182, 30294, 30091, 34445, 35988],
  'pian': [31687, 20559, 29255, 39575, 35869, 39560, 29327, 33020, 35082, 32745, 36417],
  'piao': [39128, 28418, 29922, 31080, 21117, 22028, 23254, 32549, 27533, 30623, 34741],
  'pie': [25735, 30629, 20031, 33508, 27669],
  'pin': [25340, 39057, 36139, 21697, 32856, 25306, 23000, 23252, 27008, 29277, 39078],
  'ping': [20050, 22378, 33529, 33805, 24179, 20973, 29942, 35780, 23631, 20444, 23049, 26544, 40070],
  'po': [22369, 27900, 39047, 23110, 30772, 39748, 36843, 31893, 21493, 37169, 28325, 29632, 38027, 38071, 30372, 31544],
  'pou': [21078, 35026, 36387],
  'pu': [25169, 38138, 20166, 33670, 33889, 33769, 33970, 22484, 26420, 22275, 26222, 28006, 35889, 26333, 28689, 21261, 22103, 28654, 29854, 27654, 38244, 38248, 36476],
  'qi': [26399, 27450, 26646, 25114, 22971, 19971, 20932, 28422, 26578, 27791, 20854, 26827, 22855, 27495, 30054, 23822, 33040, 40784, 26071, 31048, 31041, 39569, 36215, 23682, 20062, 20225, 21551, 22865, 30732, 22120, 27668, 36804, 24323, 27773, 27875, 35755, 20127, 20115, 22331, 33425, 33803, 33914, 22017, 23674, 23696, 27732, 28103, 39568, 32494, 29738, 29734, 26462, 26724, 27117, 27449, 31098, 25001, 30875, 34548, 34590, 32166, 32174, 36287, 36426, 40141, 40594],
  'qia': [25488, 24688, 27965, 33884],
  'qian': [29301, 25190, 38030, 38085, 21315, 36801, 31614, 20191, 35878, 20094, 40660, 38065, 38067, 21069, 28508, 36963, 27973, 35892, 22545, 23884, 27424, 27465, 20325, 38433, 33418, 33441, 33640, 25518, 23693, 24749, 24906, 39582, 25652, 35120, 32561, 26912, 32951, 24838, 38052, 34388, 31645],
  'qiang': [26538, 21595, 33108, 32652, 22681, 34103, 24378, 25250, 23281, 27183, 25111, 28829, 38166, 38197, 38250, 35137, 34595, 32671, 36331, 36292],
  'qiao': [27207, 38201, 25970, 24708, 26725, 30631, 20052, 20392, 24039, 38808, 25772, 32728, 23789, 20431, 31373, 21121, 35822, 35887, 33630, 24832, 24980, 32562, 27189, 27635, 30807, 36343, 38802],
  'qie': [20999, 33540, 19988, 24623, 31363, 37060, 21820, 24812, 22974, 25352, 38194, 31655],
  'qin': [38054, 20405, 20146, 31206, 29748, 21220, 33465, 25810, 31165, 23517, 27777, 33449, 33985, 34162, 25599, 21539, 21994, 22105, 28337, 27278, 34707, 34942],
  'qing': [38738, 36731, 27682, 20542, 21375, 28165, 25806, 26228, 27696, 24773, 39031, 35831, 24198, 20521, 33496, 22282, 27296, 30956, 34619, 32580, 31632, 35622, 40109, 40677],
  'qiong': [29756, 31351, 37019, 33557, 31353, 31559, 37518],
  'qiu': [31179, 19992, 37041, 29699, 27714, 22234, 37195, 27845, 20421, 27709, 24047, 33405, 29360, 28267, 36881, 36946, 27000, 36167, 40480, 34412, 34479, 34660, 35032, 31959, 40133, 40765],
  'qu': [36235, 21306, 34502, 26354, 36527, 23624, 39537, 28192, 21462, 23094, 40843, 36259, 21435, 35790, 21164, 34134, 34343, 23702, 34914, 38418, 29865, 35281, 27661, 31067, 30962, 30319, 34512, 34876, 40628, 30655, 40674],
  'quan': [22280, 39079, 26435, 37275, 27849, 20840, 30154, 25331, 29356, 21048, 21149, 35808, 33603, 29566, 24731, 32507, 36737, 30030, 38120, 34615, 31564, 39688],
  'que': [32570, 28820, 30264, 21364, 40522, 27063, 30830, 38592, 38425, 24747],
  'qun': [35033, 32676, 36897],
  'ran': [28982, 29123, 20873, 26579, 33490, 39663],
  'rang': [29924, 22756, 25880, 22199, 35753, 31155, 31344],
  'rao': [39286, 25200, 32469, 33627, 23046, 26721],
  'ruo': [24825, 33509, 24369],
  're': [28909, 20556],
  'ren': [22764, 20161, 20154, 24525, 38887, 20219, 35748, 20995, 22922, 32427, 20190, 33615, 33882, 39274, 36715, 31252, 34941],
  'reng': [25172, 20173],
  'ri': [26085],
  'rong': [25102, 33592, 33993, 33635, 34701, 29076, 28342, 23481, 32466, 20887, 23896, 29416, 32539, 27029, 34686],
  'rou': [25545, 26580, 32905, 31941, 36418, 38819],
  'ru': [33593, 34837, 20754, 23418, 22914, 36785, 20083, 27741, 20837, 35109, 34000, 34231, 22149, 27955, 28349, 28641, 38135, 35174, 39077],
  'ruan': [36719, 38446, 26378],
  'rui': [34122, 29790, 38160, 33454, 34148, 30591, 34443],
  'run': [38384, 28070],
  'sa': [25746, 27922, 33832, 21317, 20200, 25394, 39122],
  'sai': [33134, 40131, 22622, 36187, 22139],
  'san': [19977, 21441, 20254, 25955, 24417, 39315, 27701, 27637, 31937, 38704],
  'sang': [26705, 21971, 20007, 25633, 30921, 39073],
  'sao': [25620, 39578, 25195, 23234, 22525, 33226, 30233, 40139],
  'se': [29791, 33394, 28073, 21868, 38121, 38127, 31313],
  'sen': [26862],
  'seng': [20711],
  'sha': [33678, 30722, 26432, 21049, 27801, 32433, 20667, 21861, 29022, 33038, 27459, 30183, 35039, 38670, 40104],
  'shai': [31579, 26194, 37246],
  'shan': [29642, 33515, 26441, 23665, 21024, 29053, 34923, 38378, 38485, 25797, 36193, 33203, 21892, 27733, 25159, 32558, 21089, 35754, 37167, 22479, 33439, 28536, 22999, 39583, 33211, 38032, 30109, 34798, 33314, 36314, 40157],
  'shang': [22674, 20260, 21830, 36175, 26188, 19978, 23578, 35059, 22439, 32497, 27527, 29109, 35294],
  'shao': [26786, 25422, 31245, 28903, 33421, 21242, 38902, 23569, 21736, 37045, 32461, 21165, 33493, 28530, 34552, 31524, 31602, 33348],
  'she': [22882, 36170, 34503, 33292, 33293, 36198, 25668, 23556, 24913, 28041, 31038, 35774, 21389, 20312, 29470, 30066, 40605],
  'shen': [30775, 30003, 21627, 20280, 36523, 28145, 23072, 32453, 31070, 27784, 23457, 23158, 29978, 32958, 24910, 28183, 35804, 35842, 21554, 21698, 28182, 26937, 30695, 34563],
  'sheng': [22768, 29983, 29989, 29298, 21319, 32499, 30465, 30427, 21097, 32988, 22307, 19998, 28177, 23221, 30490, 31513],
  'shi': [24072, 22833, 29422, 26045, 28287, 35799, 23608, 34417, 21313, 30707, 25342, 26102, 20160, 39135, 34432, 23454, 35782, 21490, 30690, 20351, 23630, 39542, 22987, 24335, 31034, 22763, 19990, 26623, 20107, 25325, 35475, 36893, 21183, 26159, 21980, 22124, 36866, 20181, 20365, 37322, 39280, 27663, 24066, 24643, 23460, 35270, 35797, 35877, 22488, 33715, 33997, 24337, 21777, 39267, 36732, 32774, 36147, 28859, 31035, 38088, 38090, 34731, 33296, 31598, 35925, 40101, 40122],
  'shou': [25910, 25163, 39318, 23432, 23551, 25480, 21806, 21463, 30246, 20861, 25164, 29417, 32502, 33359],
  'shu': [34092, 26530, 26803, 27530, 25234, 36755, 21460, 33298, 28113, 30095, 20070, 36174, 23408, 29087, 34223, 26257, 26329, 32626, 34560, 40653, 40736, 23646, 26415, 36848, 26641, 26463, 25101, 31446, 22661, 24246, 25968, 28465, 24661, 20495, 22654, 33789, 24516, 27821, 28049, 28557, 23005, 32446, 27641, 33127, 27571, 38255, 31211, 40556],
  'shua': [21047, 32781, 21808, 28078],
  'shuai': [25684, 34928, 29993, 24069, 34752],
  'shuan': [26643, 25332, 38377],
  'shuang': [38684, 21452, 29245, 23360],
  'shui': [35841, 27700, 30561, 31246],
  'shun': [21550, 30636, 39034, 33308, 24642],
  'shuo': [35828, 30805, 26388, 28865, 33972, 25632, 21965, 28655, 22913, 27082, 38084],
  'si': [26031, 25749, 22070, 24605, 31169, 21496, 19997, 27515, 32902, 23546, 21987, 22235, 20282, 20284, 39282, 24051, 21422, 20447, 20821, 33765, 21661, 27740, 27863, 28556, 22994, 39543, 32524, 31040, 31072, 38198, 40502, 32796, 34547, 31525],
  'song': [26494, 32824, 24578, 39042, 36865, 23435, 35772, 35829, 20935, 33752, 23847, 23913, 24554, 24730, 28126, 31462],
  'sou': [25628, 33368, 25822, 22013, 21471, 21974, 22014, 39306, 28338, 39125, 30605, 38204, 34699],
  'su': [33487, 37221, 20439, 32032, 36895, 31903, 20723, 22609, 28335, 23487, 35785, 32899, 22809, 35873, 34060, 21961, 24875, 31756, 35307, 31267],
  'suan': [37240, 33948, 31639],
  'sui': [34429, 38539, 38543, 32485, 39635, 30862, 23681, 31319, 36930, 38567, 31071, 34001, 20907, 35847, 28617, 36995, 29159, 30509, 30562],
  'sun': [23385, 25439, 31499, 33642, 29426, 39143, 27051, 36323, 38588],
  'suo': [26797, 21766, 32553, 29712, 32034, 38145, 25152, 21794, 21990, 23057, 26731, 30531, 32679],
  'ta': [22604, 20182, 23427, 22905, 22612, 29549, 25374, 36427, 36367, 38396, 28347, 36962, 27067, 27795],
  'tai': [32974, 33492, 25260, 21488, 27888, 37214, 22826, 24577, 27760, 37040, 34233, 32957, 28849, 38043, 36294, 40080],
  'tan': [22349, 25674, 36138, 30251, 28393, 22363, 27264, 30192, 28525, 35885, 35848, 22374, 27631, 34962, 30899, 25506, 21497, 28845, 37103, 34120, 26137, 38077, 38188, 35203],
  'tang': [27748, 22616, 25642, 22530, 26848, 33179, 21776, 31958, 20645, 39271, 28303, 29805, 38132, 38231, 32805, 34711, 34739, 32688, 37283],
  'thang': [20504, 36538, 28108],
  'theng': [36255, 28907],
  'tao': [25487, 28059, 28372, 32486, 33796, 26691, 36867, 28120, 38518, 35752, 22871, 25361, 40727, 21845, 38892, 39253],
  'te': [29305],
  'teng': [34276, 33150, 30140, 35466, 28373],
  'ti': [26799, 21076, 36386, 38161, 25552, 39064, 36420, 21884, 20307, 26367, 22159, 24789, 28053, 21059, 23625, 33617, 24716, 36886, 32488, 32519, 40520, 35068, 37261],
  'tian': [22825, 28155, 22635, 30000, 29980, 24684, 33300, 33094, 25517, 24541, 38423, 27524, 30027, 38079, 34490],
  'tiao': [26465, 36834, 30522, 36339, 20347, 31079, 38123, 31381, 40838, 40102],
  'tie': [36148, 38081, 24086, 33820, 39214],
  'ting': [21381, 21548, 28867, 27712, 24311, 20572, 20141, 24237, 25402, 33351, 33691, 33910, 23159, 26755, 34579, 38662],
  'tong': [36890, 26704, 37230, 30643, 21516, 38108, 24420, 31461, 26742, 25413, 31570, 32479, 30171, 20319, 20718, 20189, 33596, 22005, 24696, 28540, 30780],
  'tou': [20599, 25237, 22836, 36879, 20128],
  'tu': [20984, 31171, 31361, 22270, 24466, 36884, 28034, 23648, 22303, 21520, 20820, 22541, 33660, 33759, 38029, 37236],
  'tuan': [28237, 22242, 30083],
  'tui': [25512, 39059, 33151, 34581, 35114, 36864, 24530, 29050],
  'tun': [21534, 23663, 33216, 39272, 26302, 35930, 31360],
  'tuo': [25302, 25176, 33073, 40501, 38464, 39534, 39548, 26925, 22949, 25299, 21822, 20039, 20311, 22376, 24249, 27825, 26589, 30755, 31656, 33284, 36302, 40717],
  'wa': [25366, 21703, 34521, 27964, 23043, 29926, 34972, 20324, 23090, 33149],
  'wai': [27498, 22806],
  'wan': [35916, 24367, 28286, 29609, 39037, 20024, 28919, 23436, 30871, 25405, 26202, 30358, 24779, 23451, 23113, 19975, 33109, 21084, 33412, 33483, 33728, 32424, 32510, 29740, 33048, 30073, 34623, 31650],
  'wang': [27754, 29579, 20129, 26505, 32593, 24448, 26106, 26395, 24536, 22916, 32596, 23586, 24792, 36747, 39757],
  'wei': [23041, 24013, 24494, 21361, 38886, 36829, 26693, 22260, 21807, 24799, 20026, 28493, 32500, 33479, 33806, 22996, 20255, 20266, 23614, 32428, 26410, 34074, 21619, 30031, 32963, 21890, 39759, 20301, 28205, 35859, 23561, 24944, 21355, 20525, 20558, 35839, 38536, 33907, 34183, 24079, 24119, 23860, 23916, 29477, 29484, 38385, 27817, 27943, 28064, 36918, 23059, 29614, 38890, 36558, 28828, 29032, 29096, 30207, 33353, 40084],
  'wen': [30239, 28201, 34442, 25991, 38395, 32441, 21563, 31283, 32010, 38382, 21006, 24864, 38412, 27766, 29882, 38891, 27521, 38639],
  'weng': [21985, 32705, 29934, 33994, 34169],
  'wo': [25373, 34583, 28065, 31389, 25105, 26017, 21351, 25569, 27779, 33716, 24132, 28197, 26444, 32927, 40844],
  'wu': [24043, 21596, 38056, 20044, 27745, 35820, 23627, 26080, 33436, 26791, 21566, 21556, 27595, 27494, 20116, 25410, 21320, 33310, 20237, 20398, 22366, 25098, 38654, 26212, 29289, 21247, 21153, 24735, 35823, 20800, 20213, 38434, 37036, 22316, 33460, 24209, 24579, 24548, 28015, 23524, 36821, 22953, 39579, 29310, 28944, 40521, 40540, 34568, 37576, 40751],
  'xi': [26132, 29081, 26512, 35199, 30802, 30717, 26224, 22075, 21560, 38177, 29306, 31232, 24687, 24076, 24713, 33181, 22805, 24796, 29060, 28911, 28330, 27728, 29312, 27268, 34989, 24109, 20064, 23219, 21916, 38115, 27927, 31995, 38553, 25103, 32454, 20694, 20846, 38576, 37079, 33564, 33912, 34032, 22874, 21775, 24473, 39273, 38411, 28000, 28101, 23651, 23305, 29626, 27176, 26342, 35275, 27447, 29113, 31114, 31143, 38072, 30361, 31352, 34597, 34763, 33342, 32690, 31902, 32725, 37295, 40759],
  'xia': [30606, 34430, 21283, 38686, 36758, 26247, 23777, 20384, 29421, 19979, 21414, 22799, 21523, 25472, 33901, 21956, 29390, 36944, 29781, 30806, 30229, 32581, 40672],
  'xian': [38184, 20808, 20185, 40092, 32420, 21688, 36132, 34900, 33335, 38386, 28046, 24358, 23244, 26174, 38505, 29616, 29486, 21439, 33146, 39301, 32673, 23466, 38519, 38480, 32447, 20924, 34259, 23704, 29443, 26297, 23092, 27673, 31046, 40519, 30187, 34476, 31557, 31868, 37232, 36345],
  'xiang': [30456, 21410, 38262, 39321, 31665, 35140, 28248, 20065, 32724, 31077, 35814, 24819, 21709, 20139, 39033, 24055, 27233, 20687, 21521, 35937, 33431, 33881, 39287, 24224, 39591, 32515, 34771, 40094, 39144],
  'xiao': [33831, 30813, 38660, 21066, 21742, 22179, 38144, 28040, 23477, 28102, 26195, 23567, 23389, 26657, 32918, 21880, 31505, 25928, 21715, 21691, 23844, 28487, 36877, 39553, 32481, 26541, 26549, 31601, 31659, 39752],
  'xie': [26964, 20123, 27463, 34638, 38795, 21327, 25375, 25658, 37034, 26012, 32961, 35856, 20889, 26800, 21368, 34809, 25032, 27844, 27899, 35874, 23633, 20565, 20149, 21232, 29166, 34212, 25783, 24296, 28707, 36994, 32449, 32556, 27053, 27021, 27481, 36510],
  'xin': [34218, 33455, 38156, 27427, 36763, 26032, 24571, 24515, 20449, 34885, 22239, 39336, 33688, 27462, 38141, 37995],
  'xing': [26143, 33125, 29481, 24826, 20852, 21009, 22411, 24418, 37026, 34892, 37266, 24184, 26447, 24615, 22995, 38473, 33607, 33637, 25828, 24763, 30798],
  'xiong': [20804, 20982, 33016, 21256, 27769, 38596, 29066, 33422],
  'xiu': [20241, 20462, 32670, 26429, 21957, 38152, 31168, 34966, 32483, 33696, 23723, 39312, 24229, 40506, 35973, 39673],
  'xu': [22687, 25100, 38656, 34394, 22040, 39035, 24464, 35768, 33988, 37207, 21465, 26093, 24207, 30044, 24676, 32110, 23167, 32490, 32493, 35764, 35817, 22313, 34047, 24629, 27947, 28294, 39036, 26665, 29030, 30729, 30449, 32997, 31944, 37265],
  'xuan': [36713, 21927, 23459, 24748, 26059, 29572, 36873, 30307, 30505, 32474, 20743, 35862, 33841, 25550, 39316, 27883, 27957, 28210, 28457, 29831, 26982, 26244, 28843, 29002, 30905, 38089, 38239, 30147],
  'xue': [38772, 34203, 23398, 31348, 38634, 34880, 22129, 27894, 40149],
  'xun': [21195, 29071, 24490, 26092, 35810, 23547, 39535, 24033, 27529, 27739, 35757, 35759, 36874, 36805, 24061, 22489, 33600, 34224, 23755, 24455, 27988, 26331, 31400, 37306, 40095],
  'ya': [21387, 25276, 40486, 40493, 21568, 20011, 33469, 29273, 34460, 23830, 34905, 28079, 38597, 21713, 20122, 35766, 20258, 25568, 21526, 23688, 36819, 23045, 29706, 26720, 27689, 30737, 30554, 30166],
  'yan': [28937, 21693, 38409, 28895, 28153, 30416, 20005, 30740, 34578, 23721, 24310, 35328, 39068, 38414, 28814, 27839, 22852, 25513, 30524, 34893, 28436, 33395, 22576, 29141, 21388, 30746, 38593, 21761, 24422, 28976, 23476, 35866, 39564, 21411, 38757, 36189, 20456, 20547, 20822, 35744, 35891, 37118, 37154, 33451, 33784, 23846, 24697, 38379, 38415, 27911, 28270, 28383, 22925, 23267, 29744, 26191, 33005, 33100, 28977, 32616, 31605, 37245, 39751, 39181, 40761],
  'yang': [27523, 22830, 40495, 31207, 26472, 25196, 20335, 30113, 32650, 27915, 38451, 27687, 20208, 30162, 20859, 26679, 28478, 24457, 24591, 27889, 28800, 28874, 24665, 34520, 38789],
  'yao': [36992, 33136, 22934, 29814, 25671, 23591, 36965, 31377, 35875, 23002, 21676, 33280, 33647, 35201, 32768, 22829, 29243, 21510, 23870, 24493, 28729, 24186, 29671, 26483, 26332, 32948, 40542, 31368, 32327, 40144],
  'ye': [26928, 22094, 32822, 29239, 37326, 20918, 20063, 39029, 25494, 19994, 21494, 26355, 33099, 22812, 28082, 35858, 37050, 25590, 39296, 26196, 28904, 38104],
  'yi': [19968, 22777, 21307, 25558, 38129, 20381, 20234, 34915, 39056, 22839, 36951, 31227, 20202, 33008, 30097, 27778, 23452, 23016, 24413, 26885, 34433, 20506, 24050, 20057, 30691, 20197, 33402, 25233, 26131, 37009, 23673, 20159, 24441, 33222, 36920, 32900, 30123, 20134, 35028, 24847, 27589, 24518, 20041, 30410, 28322, 35811, 35758, 35850, 35793, 24322, 32764, 32716, 32462, 21000, 21139, 20350, 35794, 22314, 22319, 22520, 25087, 33505, 34191, 24328, 22869, 25401, 24331, 21587, 21670, 21695, 22123, 23748, 23991, 29463, 39284, 24639, 24609, 24722, 28458, 36836, 39551, 32546, 27562, 36155, 26070, 29088, 38023, 38226, 38257, 30157, 30231, 30292, 32714, 34916, 34612, 33315, 32703, 32755, 37199, 40671],
  'yin': [33589, 33643, 22240, 27575, 38899, 38452, 23035, 21535, 38134, 28139, 23493, 39278, 23609, 24341, 38544, 21360, 32996, 37150, 22553, 33562, 21905, 29434, 22820, 27684, 38111, 30270, 34451, 38698, 40840],
  'ying': [33521, 27185, 23156, 40560, 24212, 32552, 33721, 33828, 33829, 33639, 34631, 36814, 36194, 30408, 24433, 39062, 30828, 26144, 23348, 37090, 33556, 33722, 33830, 25732, 22052, 33210, 28386, 28486, 28699, 29787, 29838, 27001, 40550, 30271, 39053, 32578],
  'yo': [21727, 21815],
  'yong': [25317, 20323, 33219, 30152, 24248, 38605, 36362, 34553, 21647, 27891, 28044, 27704, 24703, 21191, 29992, 20433, 22725, 22665, 24949, 37013, 38235, 29996, 40153, 39252],
  'you': [24189, 20248, 24736, 24551, 23588, 30001, 37038, 38080, 29369, 27833, 28216, 37193, 26377, 21451, 21491, 20305, 37321, 35825, 21448, 24188, 21347, 25912, 20369, 33720, 21606, 22271, 23461, 26586, 29495, 29270, 38101, 30115, 34659, 40063, 40669, 40748],
  'yu': [36802, 28132, 20110, 30402, 27014, 34398, 24858, 33286, 20313, 20446, 36926, 40060, 24841, 28189, 28180, 38533, 20104, 23089, 38632, 19982, 23679, 31161, 23431, 35821, 32701, 29577, 22495, 33419, 37057, 21505, 36935, 21947, 23786, 24481, 24840, 27442, 29425, 32946, 35465, 28020, 23507, 35029, 39044, 35947, 39533, 31162, 27603, 20251, 20451, 35840, 35861, 33848, 34019, 25540, 21889, 22276, 22281, 23899, 29427, 39275, 24254, 38408, 22954, 22948, 32417, 29788, 26161, 35278, 33140, 27428, 26044, 29020, 29152, 32895, 38064, 40518, 30224, 30208, 31411, 34643, 31485, 33281, 38633, 40841],
  'yuan': [40499, 28170, 20900, 20803, 22435, 34945, 21407, 25588, 36757, 22253, 21592, 22278, 29503, 28304, 32536, 36828, 33489, 24895, 24616, 38498, 22636, 27781, 23195, 29783, 27260, 29232, 30498, 40482, 34696, 40715],
  'yue': [26352, 32422, 36234, 36291, 38053, 23731, 31908, 26376, 24742, 38405, 40864, 27198, 21014, 38074],
  'yun': [32792, 20113, 37095, 21248, 38504, 20801, 36816, 34164, 37213, 26197, 38901, 23381, 37075, 33464, 29377, 24701, 32429, 27538, 26112, 27698],
  'za': [21277, 30776, 26434, 25334, 21634],
  'zai': [26685, 21705, 28798, 23472, 36733, 20877, 22312, 21681, 23869, 30014],
  'zan': [25874, 26242, 36190, 29906, 26141, 31786, 31948, 36273, 37694],
  'zang': [36163, 33039, 33900, 22872, 25109, 33255],
  'zao': [36973, 31967, 20991, 34299, 26531, 26089, 28577, 34468, 36481, 22122, 36896, 30338, 28790, 29157, 21795, 32555],
  'ze': [36131, 25321, 21017, 27901, 20164, 36188, 21863, 36846, 26115, 31534, 31654, 33332],
  'zei': [36156],
  'zen': [24590, 35886],
  'zeng': [22686, 24974, 26366, 36192, 32559, 29969, 32638, 38147],
  'zha': [25166, 21939, 28195, 26413, 36711, 38113, 38392, 30504, 26629, 27048, 21643, 20045, 28856, 35784, 25592, 21522, 21668, 21747, 24589, 30751, 30148, 34481, 40772],
  'zhai': [25688, 25995, 23429, 31364, 20538, 23528, 30758],
  'zhan': [30651, 27617, 35449, 31896, 27838, 30415, 26025, 36759, 23853, 23637, 34360, 26632, 21344, 25112, 31449, 28251, 32509, 35893, 25612, 26051],
  'zhang': [27167, 31456, 24432, 28467, 24352, 25484, 28072, 26454, 19976, 24080, 36134, 20183, 32960, 30260, 38556, 20169, 37155, 24155, 23938, 29520, 23260, 29835, 34769],
  'zhao': [25307, 26157, 25214, 27836, 36213, 29031, 32617, 20806, 32903, 21484, 29226, 35791, 26873, 38026, 31498],
  'zhe': [36974, 25240, 21746, 34544, 36761, 32773, 38167, 34071, 36825, 27993, 35882, 38508, 26584, 36740, 30932, 40551, 35098, 34567, 36205],
  'zhen': [29645, 26015, 30495, 29956, 30759, 33275, 36126, 38024, 20390, 26517, 30137, 35786, 38663, 25391, 38215, 38453, 32540, 26722, 27035, 36728, 36168, 32983, 26389, 31087, 30043, 40489],
  'zheng': [33976, 25379, 30529, 24449, 29424, 20105, 24596, 25972, 25327, 27491, 25919, 24103, 30151, 37073, 35777, 35812, 23781, 38066, 38126, 31581],
  'zhi': [33437, 26525, 25903, 21553, 34584, 30693, 32930, 33026, 27713, 20043, 32455, 32844, 30452, 26893, 27542, 25191, 20540, 20356, 22336, 25351, 27490, 36286, 21482, 26088, 32440, 24535, 25370, 25527, 33267, 33268, 32622, 24092, 23769, 21046, 26234, 31209, 31258, 36136, 28825, 30164, 28382, 27835, 31378, 21358, 38495, 37061, 22516, 33463, 25709, 24089, 24558, 24408, 21675, 39576, 26633, 26547, 26624, 26702, 36725, 36734, 25908, 36157, 33187, 31049, 31063, 40697, 38601, 40503, 30179, 34541, 32119, 37231, 36310, 36396, 36399, 35960, 35311],
  'zhong': [20013, 30405, 24544, 38047, 34935, 32456, 31181, 32959, 37325, 20210, 20247, 20898, 38202, 34749, 33282, 33327, 36405],
  'zhou': [33311, 21608, 24030, 27954, 35788, 31909, 36724, 32920, 24090, 21650, 30385, 23449, 26172, 39588, 21828, 30528, 20508, 35833, 33646, 39739, 32419, 32964, 30881, 31808, 33331, 37198, 40119],
  'zhu': [29664, 26666, 34523, 26417, 29482, 35832, 35803, 36880, 31481, 28891, 29038, 25284, 30633, 22065, 20027, 33879, 26609, 21161, 34496, 36142, 38136, 31569, 20303, 27880, 31069, 39547, 20267, 20367, 37054, 33486, 33585, 27929, 28186, 28532, 39546, 26492, 27104, 27237, 28855, 38114, 30128, 30211, 34480, 31482, 31672, 32741, 36485, 40584],
  'zhua': [25235],
  'zhuai': [25341],
  'zhuan': [19987, 30742, 36716, 25776, 36186, 31686, 25247, 21869, 39067],
  'zhuang': [26729, 24196, 35013, 22918, 25758, 22766, 29366, 20012],
  'zhui': [26894, 38181, 36861, 36184, 22368, 32512, 33809, 39571, 32530],
  'zhun': [35846, 20934],
  'zhuo': [25417, 25305, 21331, 26700, 29730, 33537, 37196, 28796, 27978, 20524, 35836, 24308, 34142, 25826, 21852, 27998, 28095, 26451, 28975, 31130, 26027],
  'zi': [20857, 21672, 36164, 23039, 28363, 28100, 23388, 32043, 20180, 31869, 28371, 23376, 33258, 28173, 23383, 35864, 23915, 22986, 23411, 32513, 26771, 36750, 36160, 24675, 30502, 38193, 31213, 32788, 31531, 31906, 35292, 35390, 40123, 39661],
  'zong': [39683, 26837, 36394, 23447, 32508, 24635, 32437, 33113, 31933],
  'zou': [37049, 36208, 22863, 25549, 37177, 40112],
  'zu': [31199, 36275, 21330, 26063, 31062, 35781, 38459, 32452, 20430, 33785, 21840, 24450, 39541, 36468],
  'zuan': [38075, 32386, 25893, 32565],
  'zui': [22068, 37257, 26368, 32618],
  'zun': [23562, 36981, 25753, 27197, 40159],
  'zuo': [26152, 24038, 20304, 26590, 20570, 20316, 22352, 24231, 38429, 38460, 32985, 31066, 37218],
  'cou': [34222, 26993, 36751, 33120],
  'nang': [25902, 21725, 22228, 39317, 26345],
  'o': [21908],
  'dia': [22002],
  'chuai': [22060, 33194, 36409],
  'cen': [23697, 28052],
  'diu': [38117],
  'nou': [32808],
  'fou': [32566],
  'bia': [39647]
}
/**
 *
 * @param {String} value
 * @param {Object} config capitalize upperfirst
 */
export default function chineseToPinyin (value, modifiers = { capitalize: false, upperfirst: true }) {
  const chars = value.split('')
  const length = chars.length
  if (length) {
    for (var i = 0; i < length; i++) {
      var char = chars[i]
      chars[i] = handle(char, modifiers)
    }
    value = chars.join('')
    // update: 不要单词之间的空格（保留代码，或许后面又变更）
    // if (value.slice(-1) === ' ') {
    //     value = value.slice(0, -1)
    // }
  }
  function handle (char, modifiers) {
    var charPoint = char.charCodeAt(0)
    for (var p in chinesePointCode) {
      if (chinesePointCode[p].indexOf(charPoint) > -1) {
        // update: 不要单词之间的空格（保留代码，或许后面又变更）
        // char = p + ' '
        char = p
        // handle modifiers
        // 处理 modifiers
        if (modifiers.capitalize) {
          char = char.toUpperCase()
        } else if (modifiers.upperfirst) {
          char = char.charAt(0).toUpperCase() + char.slice(1)
        }
      }
    }
    return char
  }
  return value
}
