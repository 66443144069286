<template>
  <div class="align-center">
    <div style="margin:0 1mm">
      <p style="margin-bottom:1mm; padding-top:1mm;">{{data.skuCode}}</p>
      <div>
        <svg :class="'barcodeSvg'+uuid" />
      </div>
    </div>
    <div class="align-right" style="width:80%; margin:0 2mm;line-height:1.1">
      <p
        style="word-break: break-all"
      >Att:{{data.productVariantAttributes && data.productVariantAttributes.join('/') || ''}}</p>
      <p style="word-break: break-all">PO:{{data.purchaseOrderNumber || ''}}</p>
      <p style="word-break: break-all">TNumber:{{ data.purchaseBatchNo || ''}}</p>
      <p style="word-break: break-all">Loc:{{data.locationCode || ''}}</p>
    </div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'

export default {
  name: 'PrintBatchNo',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    uuid: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
    }
  },
  watch: {
    data () {
      this.$nextTick(() => {
        this.renderBarcode()
      })
    }
  },
  mounted () {
    this.renderBarcode()
  },
  methods: {
    renderBarcode () {
      JsBarcode('.barcodeSvg' + this.uuid, this.data.purchaseBatchNo, {
        height: 35,
        fontSize: 0,
        margin: 0
      })
    }
  }
}
</script>
<style lang='scss' >
</style>
<style scoped lang="scss">
</style>
