<template>
  <div class="print-container" v-show="visible">
    <div id="printContent">
      <div v-for="(item, index) in items" :key="'index'+index" style="page-break-after: always">
        <component :is="printComponent" :data="item" :uuid="index"></component>
      </div>
    </div>
  </div>
</template>

<script>
import SlPrint from '@/shared/util/printarea.js'
import chineseToPinyin from '@/shared/util/chineseToPinyin'
import PrintBatchNo from './PrintBatchNo.vue'
import PrintBrandBatchNo from './PrintBrandBatchNo.vue'
import OemInvoicePrint from '@/views/oemViews/oemDeliveryList/OemInvoicePrint.vue'

export default {
  name: 'MainBatchPrint',
  components: {
    PrintBatchNo,
    PrintBrandBatchNo,
    OemInvoicePrint
  },
  props: {
  },
  data () {
    return {
      items: [],
      visible: false,
      printComponent: null // PrintBatchNo 普通品牌批次号 PrintBrandBatchNo vivaia品牌批次号  OemInvoicePrint OEM发货单
    }
  },
  computed: {},
  methods: {
    show (data, type) {
      this.printComponent = type
      this.appendStyle()
      this.visible = true
      this.items = []
      if (Array.isArray(data)) {
        this.items = data
        if (['PrintBatchNo', 'PrintBrandBatchNo'].includes(type)) {
          this.items = data.map(item => {
            item.productVariantAttributes = (item.productVariantAttributes || []).map(
              (ele) => {
                if (ele) {
                  return chineseToPinyin(ele)
                }
              }
            )
            return item
          })
        }

        setTimeout(() => {
          this.toPrint()
        })
      } else {
        this.$message({
          message: '数据不存在，请确认数据无误！',
          type: 'warning'
        })
        this.visible = false
      }
    },
    toPrint () {
      const vm = this
      let sl = new SlPrint({
        ids: '#printContent',
        endCallback () {
          // 移除样式
          vm.removeStyle()
          vm.visible = false
        }
      })
      setTimeout(function () {
        vm.$nextTick(() => {
          sl.init()
        })
      }, 1000)
    },
    // 添加打印样式
    appendStyle () {
      const pageStyleMap = {
        '60*40': `
            @media print {
              @page {
                size: 60mm 40mm;
                margin: 0;
              }
            }
          `,
        '75*90': `
            @media print {
              @page {
                size: 75mm 90mm;
                margin: 0;
              }
            }
          `,
        'A4': `
            @media print {
              @page {
                size: A4;
                margin: 20px auto 0;
              }
            }
          `
      }
      const componentPageMap = {
        'PrintBatchNo': '60*40',
        'PrintBrandBatchNo': '75*90',
        'OemInvoicePrint': 'A4'
      }

      let head = document.head || document.getElementsByTagName('head')[0]
      let style = document.createElement('style')
      style.setAttribute('type', 'text/css')
      style.id = 'customStyle'
      style.appendChild(document.createTextNode(pageStyleMap[componentPageMap[this.printComponent]]))
      head.appendChild(style)
    },
    // 移除打印样式
    removeStyle () {
      let listA = document.getElementById('customStyle')
      if (listA) {
        listA.parentNode.removeChild(document.getElementById('customStyle'))
      }
    }
  }
}
</script>
<style lang='scss' >
</style>
<style scoped lang="scss">
.print-container {
  height: 0;
  overflow: hidden;
}
</style>
